import { useCallback, useMemo } from 'react';
import { useTheme } from 'styled-components';

import { BankCode, BankName } from '@libs/backend-common';
import { screenSizes } from '@libs/theme';
import { WithdrawalMethods } from '@libs/types';
import {
  formatAmountWithSymbol,
  getEstimatedAmount,
  getUsdOrEurCurrencySymbol,
} from '@libs/helpers';

import Typography from '../typography/Typography';
import TableCell from '../table/TableCell';
import { TablesData, LandingFeesTableProps } from './types';

import { DestinationBankName } from './styles';

import { useTablesForMobileScreen } from './feesTableHooks/useTablesForMobileScreen';
import { useFeesTables } from './feesTableHooks/useFeesTable';
import { useLandingFeesMobileTables } from './feesTableHooks/useLandingFeesMobileTable';

export const useLandingFeesTable = (
  LandingFeesTableProps: LandingFeesTableProps
) => {
  const { colors } = useTheme();
  const mobileScreen = LandingFeesTableProps.width < screenSizes.tablet;

  const textType =
    LandingFeesTableProps.width <= screenSizes.tablet
      ? 'caption'
      : 'bodyButton';

  const formatBankDestination = useCallback(
    (bankName?: string) => {
      if (bankName) {
        return BankName[bankName as BankCode];
      } else {
        return LandingFeesTableProps.anyGeorgianBank;
      }
    },
    [LandingFeesTableProps.anyGeorgianBank]
  );

  const { mobileTables } = useLandingFeesMobileTables({
    withdrawMeth: LandingFeesTableProps.withdrawMeth,
    commonCurrency: LandingFeesTableProps.commonCurrency,
    commonFee: LandingFeesTableProps.commonFee,
    currencyGelOrEur: LandingFeesTableProps.currencyGelOrEur,
    tableProccesing: LandingFeesTableProps.tableProccesing,
    commonInstant: LandingFeesTableProps.commonInstant,
    commonLimit: LandingFeesTableProps.commonLimit,
    commonUnlimited: LandingFeesTableProps.commonUnlimited,
    currencyUsd: LandingFeesTableProps.currencyUsd,
    currencyGelUsd: LandingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: LandingFeesTableProps.isJurisdictionGe,
    width: LandingFeesTableProps.width,
    walletActionDep: LandingFeesTableProps.walletActionDep,
    commonCard: LandingFeesTableProps.commonCard,
    CardIcon: LandingFeesTableProps.CardIcon,
    BankIcon: LandingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: LandingFeesTableProps.commonBankwireBogOrSepa,
    commonBankwireOther: LandingFeesTableProps.commonBankwireOther,
    defaultColor: colors.text.default,
    brandColor: colors.interactive.brandColor,
    commonMeth: LandingFeesTableProps.commonMeth,
  });

  const {
    // internalTransferData,
    // cardWithdrawalData,
    // bankWireWithdrawalData,
    cryptoWithdrawalData,
  } = useMemo(() => {
    if (!LandingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees)
      return {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      };

    const Amount = (
      <Typography
        type={textType}
        fontFamily="primaryMedium"
        color={colors.text.default}
      >
        {LandingFeesTableProps.commonUnlimited}
      </Typography>
    );

    return LandingFeesTableProps.filteredSupportedAssets.reduce(
      (acc, fee) => {
        const amount = Number(fee.fixed).toLocaleString('en-US', {
          maximumFractionDigits: 4,
        });

        const Asset = (
          <TableCell
            title={fee.assetName}
            description={fee.assetCode}
            img={fee.iconUrl}
          />
        );

        const Fee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {amount}
          </Typography>
        );

        const BlockChainFee = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {getEstimatedAmount(fee.blockchainFee || 0)}
          </Typography>
        );

        const Price = (
          <Typography
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatAmountWithSymbol({
              amount: fee.price,
              symbol: getUsdOrEurCurrencySymbol(),
              shouldHaveSpace: false,
            })}
          </Typography>
        );

        const Destination = (
          <DestinationBankName
            type={textType}
            fontFamily="primaryMedium"
            color={colors.text.default}
          >
            {formatBankDestination(fee.extraConditions?.destinationBankCode)}
          </DestinationBankName>
        );
        if (fee.isCrypto && fee.method === WithdrawalMethods.BITNET_INTERNAL) {
          acc.internalTransferData.push({
            asset: (
              <TableCell
                title={fee.assetName.split(' ').splice(0, 2).join(' ')}
                description={fee.assetCode}
                img={fee.iconUrl}
              />
            ),
            fee: Fee,
            amount: Amount,
          });
        }

        if (fee.isCrypto && fee.method === WithdrawalMethods.FIREBLOCKS) {
          let assetImg: JSX.Element | string = fee.iconUrl;
          let assetCode: string = fee.assetCode;

          if (fee.assetCode === 'USDT') {
            if (fee.extraConditions?.destinationNetwork === 1) {
              assetImg = LandingFeesTableProps.UsdtTronImg;
              assetCode = fee.assetCode + ' (TRC-20)';
            } else if (fee.extraConditions?.destinationNetwork === 3) {
              assetImg = LandingFeesTableProps.UsdtEtheriumImg;
              assetCode = fee.assetCode + ' (ERC-20)';
            } else {
              assetImg = LandingFeesTableProps.UsdtSolanaImg;
              assetCode = fee.assetCode + ' (Solana)';
            }
          }

          acc.cryptoWithdrawalData.push({
            asset: (
              <TableCell
                title={fee.assetName.split(' ').splice(0, 2).join(' ')}
                description={
                  mobileScreen
                    ? ` ${formatAmountWithSymbol({
                        amount: fee.price,
                        symbol: getUsdOrEurCurrencySymbol(),
                      })}`
                    : assetCode
                }
                img={assetImg}
              />
            ),
            fee: Fee,
            blockchainFee: BlockChainFee,
            amount: Amount,
            price: Price,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_WIRE) {
          acc.bankWireWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }

        if (!fee.isCrypto && fee.method === WithdrawalMethods.BOG_CARD) {
          acc.cardWithdrawalData.push({
            asset: Asset,
            fee: Fee,
            amount: Amount,
            destination: Destination,
          });
        }
        return acc;
      },
      {
        internalTransferData: [],
        cardWithdrawalData: [],
        bankWireWithdrawalData: [],
        cryptoWithdrawalData: [],
      } as TablesData
    );
  }, [
    LandingFeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees,
    LandingFeesTableProps.commonUnlimited,
    LandingFeesTableProps.filteredSupportedAssets,
    LandingFeesTableProps.UsdtTronImg,
    LandingFeesTableProps.UsdtEtheriumImg,
    LandingFeesTableProps.UsdtSolanaImg,
    textType,
    colors.text.default,
    formatBankDestination,
    mobileScreen,
  ]);

  const { tables } = useFeesTables({
    landingFeesWithdrawalMethods:
      LandingFeesTableProps.landingFeesWithdrawalMethods,
    commonCurrency: LandingFeesTableProps.commonCurrency,
    commonFee: LandingFeesTableProps.commonFee,
    currencyGelOrEur: LandingFeesTableProps.currencyGelOrEur,
    tableProccesing: LandingFeesTableProps.tableProccesing,
    commonInstant: LandingFeesTableProps.commonInstant,
    commonLimit: LandingFeesTableProps.commonLimit,
    commonUnlimited: LandingFeesTableProps.commonUnlimited,
    currencyUsd: LandingFeesTableProps.currencyUsd,
    currencyGelUsd: LandingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: LandingFeesTableProps.isJurisdictionGe,
    tableAssets: LandingFeesTableProps.tableAssets,
    width: LandingFeesTableProps.width,
    tableBitnetFee: LandingFeesTableProps.tableBitnetFee,
    tableBlockchainFee: LandingFeesTableProps.tableBlockchainFee,
    tableAmount: LandingFeesTableProps.tableAmount,
    tableAmountShort: LandingFeesTableProps.tableAmountShort,
    landingFeesFundingOptions: LandingFeesTableProps.landingFeesFundingOptions,
    shouldRender: LandingFeesTableProps.shouldRender,
    walletActionDep: LandingFeesTableProps.walletActionDep,
    commonCard: LandingFeesTableProps.commonCard,
    CardIcon: LandingFeesTableProps.CardIcon,
    BankIcon: LandingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: LandingFeesTableProps.commonBankwireBogOrSepa,
    landingFeesCryptoWithdrawalFees:
      LandingFeesTableProps.landingFeesCryptoWithdrawalFees,
    cryptoWithdrawalData: cryptoWithdrawalData,
    landingFeesCryptoWithdrawalMethodsDisclaimer:
      LandingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
    landingFeesFundingDisclaimer:
      LandingFeesTableProps.landingFeesFundingDisclaimer,
    commonBankwireOther: LandingFeesTableProps.commonBankwireOther,
    landingFeesWithdrawalMethodsDisclaimer:
      LandingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
    defaultColor: colors.text.default,
    brandColor: colors.interactive.brandColor,
    processingTime: LandingFeesTableProps.processingTime,
    commonUnlimitedShort: LandingFeesTableProps.commonUnlimitedShort,
    tableDestinationBank: LandingFeesTableProps.tableDestinationBank,
    withdrawalFeesRes: LandingFeesTableProps.withdrawalFeesRes,
    commonMeth: LandingFeesTableProps.commonMeth,
  });

  const { tablesForMobileScreen } = useTablesForMobileScreen({
    convertFee: LandingFeesTableProps.convertFee,
    landingFeesWithdrawalMethods:
      LandingFeesTableProps.landingFeesWithdrawalMethods,
    withdrawMeth: LandingFeesTableProps.withdrawMeth,
    commonWithdrawMethods: LandingFeesTableProps.commonWithdrawMethods,
    commonCurrency: LandingFeesTableProps.commonCurrency,
    commonFee: LandingFeesTableProps.commonFee,
    currencyGelOrEur: LandingFeesTableProps.currencyGelOrEur,
    tableProccesing: LandingFeesTableProps.tableProccesing,
    commonInstant: LandingFeesTableProps.commonInstant,
    commonLimit: LandingFeesTableProps.commonLimit,
    commonUnlimited: LandingFeesTableProps.commonUnlimited,
    currencyUsd: LandingFeesTableProps.currencyUsd,
    currencyGelUsd: LandingFeesTableProps.currencyGelUsd,
    isJurisdictionGe: LandingFeesTableProps.isJurisdictionGe,
    tableAssets: LandingFeesTableProps.tableAssets,
    width: LandingFeesTableProps.width,
    tableBitnetFee: LandingFeesTableProps.tableBitnetFee,
    tableBlockchainFee: LandingFeesTableProps.tableBlockchainFee,
    tableAmount: LandingFeesTableProps.tableAmount,
    tableAmountShort: LandingFeesTableProps.tableAmountShort,
    walletActionDeposit: LandingFeesTableProps.walletActionDeposit,
    landingFeesFundingOptions: LandingFeesTableProps.landingFeesFundingOptions,
    shouldRender: LandingFeesTableProps.shouldRender,
    walletActionDep: LandingFeesTableProps.walletActionDep,
    commonCard: LandingFeesTableProps.commonCard,
    CardIcon: LandingFeesTableProps.CardIcon,
    BankIcon: LandingFeesTableProps.BankIcon,
    commonBankwireBogOrSepa: LandingFeesTableProps.commonBankwireBogOrSepa,
    withdrawCryptoFee: LandingFeesTableProps.withdrawCryptoFee,
    landingFeesCryptoWithdrawalFees:
      LandingFeesTableProps.landingFeesCryptoWithdrawalFees,
    cryptoWithdrawalData: cryptoWithdrawalData,
    landingFeesCryptoWithdrawalMethodsDisclaimer:
      LandingFeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
    landingFeesFundingDisclaimer:
      LandingFeesTableProps.landingFeesFundingDisclaimer,
    commonBankwireOther: LandingFeesTableProps.commonBankwireOther,
    landingFeesWithdrawalMethodsDisclaimer:
      LandingFeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
    brandColor: colors.interactive.brandColor,
    defaultColor: colors.text.default,
  });

  return {
    tables,
    mobileTables,
    tablesForMobileScreen,
    cryptoWithdrawSearchValue: LandingFeesTableProps.cryptoWithdrawSearchValue,
    setCryptoWithdrawSearchValue:
      LandingFeesTableProps.setCryptoWithdrawSearchValue,
    fullyLoaded: LandingFeesTableProps.fullyLoaded,
    setFullyLoaded: LandingFeesTableProps.setFullyLoaded,
  };
};
