import { screenSizes } from '@libs/theme';
import { useMemo } from 'react';
import { FeesTablesSelectorId } from '../../constants';
import TableCell from '../../table/TableCell';
import Typography from '../../typography/Typography';
import {
  minFoundingOptWithBankWireGel,
  minFoundingOptWithBankWireUsd,
  minFoundingOptWithBankwireWithdrawEur,
  minFoundingOptWithBankwireWithdrawGel,
  minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelWithFee,
  minFoundingOptWithBankwireWithdrawUsd,
  minFoundingOptwithCard,
  minFoundingOptWithCardWithdraw,
  minFundingOptionCardOtherBank,
  minFundingOptionsWithBankWireOtherBankGel,
  minFundingOptionsWithBankWireOtherBankGelLarge,
} from '../constants';
import {
  CustomMinMaxText,
  IconWrapper,
  MinMaxText,
  StyledTableCell,
  TextWrapperDesktop,
  TextWrapperDesktopBreakSpace,
  TextWrapperMobile,
} from '../styles';
import { FeesTableProps } from '../types';

export const useFeesTables = (FeesTableProps: FeesTableProps) => {
  const titleType =
    FeesTableProps.width < screenSizes.tablet ? 'primaryMedium' : 'primaryBold';

  const textType =
    FeesTableProps.width <= screenSizes.tablet ? 'caption' : 'bodyButton';

  const gelOrEurCurrency = ' ' + FeesTableProps.currencyGelOrEur;
  const usdCurrency = ' ' + FeesTableProps.currencyUsd;

  const mobileScreen = FeesTableProps.width < screenSizes.tablet;

  const fundingOptionsData = useMemo(
    () => {
      return [
        ...(FeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <TableCell
                    title={FeesTableProps.commonCard}
                    img={
                      <IconWrapper>
                        <FeesTableProps.CardIcon
                          color={FeesTableProps.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    {FeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    <div>{minFoundingOptwithCard + '%'}</div>
                  </MinMaxText>
                ),
                processing: FeesTableProps.commonInstant,
                limit: FeesTableProps.commonUnlimited,
              },
            ]
          : []),
        {
          type: (
            <StyledTableCell
              title={FeesTableProps.commonBankwireBogOrSepa}
              img={
                <IconWrapper>
                  <FeesTableProps.BankIcon color={FeesTableProps.brandColor} />
                </IconWrapper>
              }
              titleType={titleType}
              titleSize="caption"
            />
          ),
          currency: (
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              {FeesTableProps.currencyGelUsd}
            </Typography>
          ),
          fee: FeesTableProps.isJurisdictionGe ? (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireGel + gelOrEurCurrency + ' /'}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {gelOrEurCurrency + minFoundingOptWithBankWireGel}
              </TextWrapperMobile>
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireUsd + usdCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {usdCurrency + minFoundingOptWithBankWireUsd}
              </TextWrapperMobile>
            </MinMaxText>
          ) : (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankWireUsd + gelOrEurCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {minFoundingOptWithBankWireUsd + gelOrEurCurrency}
              </TextWrapperMobile>
            </MinMaxText>
          ),
          processing: FeesTableProps.processingTime,
          limit: mobileScreen
            ? FeesTableProps.commonUnlimitedShort
            : FeesTableProps.commonUnlimited,
        },
        ...(FeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <StyledTableCell
                    title={FeesTableProps.commonBankwireOther}
                    img={
                      <IconWrapper>
                        <FeesTableProps.BankIcon
                          color={FeesTableProps.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    {FeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    <TextWrapperDesktopBreakSpace>
                      {minFundingOptionCardOtherBank + gelOrEurCurrency}
                    </TextWrapperDesktopBreakSpace>

                    <TextWrapperMobile>
                      {minFoundingOptWithBankWireGel + gelOrEurCurrency}
                    </TextWrapperMobile>
                  </MinMaxText>
                ),
                processing: FeesTableProps.commonInstant,
                limit: mobileScreen
                  ? FeesTableProps.commonUnlimitedShort
                  : FeesTableProps.commonUnlimited,
              },
            ]
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      FeesTableProps.commonCard,
      FeesTableProps.currencyGelOrEur,
      FeesTableProps.commonBankwireOther,
      FeesTableProps.currencyGelUsd,
      FeesTableProps.commonBankwireBogOrSepa,
    ]
  );

  const withdrawalOptionsData = useMemo(
    () => {
      return [
        ...(FeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <TableCell
                    title={FeesTableProps.commonCard}
                    img={
                      <IconWrapper>
                        <FeesTableProps.CardIcon
                          color={FeesTableProps.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    {FeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <MinMaxText
                    type={textType}
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    <div>{minFoundingOptWithCardWithdraw + '%'}</div>
                  </MinMaxText>
                ),
                processing: FeesTableProps.commonInstant,
                limit: FeesTableProps.commonUnlimited,
              },
            ]
          : []),
        {
          type: (
            <StyledTableCell
              title={FeesTableProps.commonBankwireBogOrSepa}
              img={
                <IconWrapper>
                  <FeesTableProps.BankIcon color={FeesTableProps.brandColor} />
                </IconWrapper>
              }
              titleType={titleType}
              titleSize="caption"
            />
          ),
          currency: (
            <Typography
              type="caption"
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              {FeesTableProps.currencyGelUsd}
            </Typography>
          ),
          fee: FeesTableProps.isJurisdictionGe ? (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              <TextWrapperDesktop>
                {minFoundingOptWithBankwireWithdrawGel + gelOrEurCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {gelOrEurCurrency + minFoundingOptWithBankwireWithdrawGel}
              </TextWrapperMobile>
              <TextWrapperDesktop>
                {minFoundingOptWithBankwireWithdrawUsd + usdCurrency}
              </TextWrapperDesktop>
              <TextWrapperMobile>
                {usdCurrency + minFoundingOptWithBankwireWithdrawUsd}
              </TextWrapperMobile>
            </MinMaxText>
          ) : (
            <MinMaxText
              type={textType}
              fontFamily="primaryMedium"
              color={FeesTableProps.defaultColor}
            >
              {minFoundingOptWithBankwireWithdrawEur + '%'}
            </MinMaxText>
          ),
          processing: FeesTableProps.processingTime,
          limit: mobileScreen
            ? FeesTableProps.commonUnlimitedShort
            : FeesTableProps.commonUnlimited,
        },

        ...(FeesTableProps.isJurisdictionGe
          ? [
              {
                type: (
                  <StyledTableCell
                    title={FeesTableProps.commonBankwireOther}
                    img={
                      <IconWrapper>
                        <FeesTableProps.BankIcon
                          color={FeesTableProps.brandColor}
                        />
                      </IconWrapper>
                    }
                    titleType={titleType}
                    titleSize="caption"
                  />
                ),
                currency: (
                  <Typography
                    type="caption"
                    fontFamily="primaryMedium"
                    color={FeesTableProps.defaultColor}
                  >
                    {FeesTableProps.currencyGelOrEur}
                  </Typography>
                ),
                fee: (
                  <>
                    <CustomMinMaxText
                      type={textType}
                      fontFamily="primaryMedium"
                      color={FeesTableProps.defaultColor}
                    >
                      <TextWrapperDesktop>
                        {'0 - ' +
                          minFoundingOptWithBankwireWithdrawTbcGelWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelWithFee}
                      </TextWrapperMobile>
                      <TextWrapperDesktop>
                        {minFundingOptionsWithBankWireOtherBankGel +
                          minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee}
                      </TextWrapperMobile>
                      <TextWrapperDesktop>
                        {minFundingOptionsWithBankWireOtherBankGelLarge +
                          minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee}
                      </TextWrapperDesktop>
                      <TextWrapperMobile>
                        {gelOrEurCurrency +
                          minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee}
                      </TextWrapperMobile>
                    </CustomMinMaxText>
                  </>
                ),
                processing: FeesTableProps.commonInstant,
                limit: mobileScreen
                  ? FeesTableProps.commonUnlimitedShort
                  : FeesTableProps.commonUnlimited,
              },
            ]
          : []),
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      FeesTableProps.commonCard,
      FeesTableProps.currencyGelOrEur,
      FeesTableProps.currencyGelUsd,
      FeesTableProps.commonBankwireBogOrSepa,
      titleType,
    ]
  );

  const tables = useMemo(() => {
    if (!FeesTableProps.withdrawalFeesRes?.getAllWithdrawalFees) {
      return [];
    }

    const methodColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonMeth}
          </Typography>
        ),
        accessor: 'type',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonCurrency}
          </Typography>
        ),
        accessor: 'currency',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily="primaryBold">
                {FeesTableProps.commonFee}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily="primaryBold">
                {FeesTableProps.commonFeeShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.tableProccesing}
          </Typography>
        ),
        accessor: 'processing',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonLimit}
          </Typography>
        ),
        accessor: 'limit',
      },
    ];

    const withdrawalColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonMeth}
          </Typography>
        ),
        accessor: 'type',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonCurrency}
          </Typography>
        ),
        accessor: 'currency',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonFee}
          </Typography>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.tableProccesing}
          </Typography>
        ),
        accessor: 'processing',
      },
      {
        Header: (
          <Typography type="caption" fontFamily="primaryBold">
            {FeesTableProps.commonLimit}
          </Typography>
        ),
        accessor: 'limit',
      },
    ];

    const cryptoColumns = [
      {
        Header: FeesTableProps.tableAssets,
        accessor: 'asset',
      },
      {
        Header: FeesTableProps.commonFee,
        accessor: 'fee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              {FeesTableProps.tableAmount}
            </TextWrapperDesktop>
            <TextWrapperMobile>
              {FeesTableProps.tableAmountShort}
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];
    const cryptoWithdrawlFeeColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableAssets}
          </Typography>
        ),
        accessor: 'asset',
      },
      ...(!mobileScreen
        ? [
            {
              Header: (
                <Typography type="caption" fontFamily={titleType}>
                  {FeesTableProps.tablePrice}
                </Typography>
              ),
              accessor: 'price',
            },
          ]
        : []),
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableBitnetFee}
          </Typography>
        ),
        accessor: 'fee',
      },

      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableBlockchainFee}
          </Typography>
        ),
        accessor: 'blockchainFee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily={titleType}>
                {FeesTableProps.tableAmount}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily={titleType}>
                {FeesTableProps.tableAmountShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];

    const fiatColumns = [...cryptoColumns];
    fiatColumns.push({
      Header: FeesTableProps.tableDestinationBank,
      accessor: 'destination',
    });
    if (FeesTableProps.shouldRender) {
      return [
        {
          title: FeesTableProps.landingFeesFundingOptions,
          columns: methodColumns,
          data: fundingOptionsData,
          disclaimerText: FeesTableProps.landingFeesFundingDisclaimer,
          selectorId: '',
          searchId: '' as FeesTablesSelectorId,
        },
        {
          title: FeesTableProps.landingFeesWithdrawalMethods,
          columns: withdrawalColumns,
          selectorId: 'withdraw-methods-second',
          searchId: '' as FeesTablesSelectorId,
          data: withdrawalOptionsData,
          disclaimerText: FeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
        },
        {
          title: FeesTableProps.landingFeesCryptoWithdrawalFees,
          columns: cryptoWithdrawlFeeColumns,
          data: FeesTableProps.cryptoWithdrawalData,
          selectorId: 'withdraw-crypto-fee-second',
          searchId: '' as FeesTablesSelectorId,
          disclaimerText:
            FeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
        },

        // {
        //   title: landingFeesInternalCryptoTransferFee,
        //   columns: cryptoColumns,
        //   data: internalTransferData,
        //   selectorId: '',
        //   searchId: '' as FeesTablesSelectorId,
        // },
      ];
    }

    return [
      ...(!mobileScreen
        ? [
            {
              title: FeesTableProps.walletActionDep,
              columns: methodColumns,
              data: fundingOptionsData,
              selectorId: 'deposit-second',
              searchId: '' as FeesTablesSelectorId,
              disclaimerText: FeesTableProps.landingFeesFundingDisclaimer,
            },
            {
              title: FeesTableProps.landingFeesWithdrawalMethods,
              columns: withdrawalColumns,
              selectorId: 'withdraw-methods-second',
              searchId: '' as FeesTablesSelectorId,
              data: withdrawalOptionsData,
              disclaimerText:
                FeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
            },
          ]
        : []),
      // {
      //   title: landingFeesWireTransferWithdrawalFees,
      //   columns: fiatColumns,
      //   data: bankWireWithdrawalData,
      //   selectorId: '',
      // },
      // {
      //   title: landingFeesCardWithdrawalFees,
      //   columns: fiatColumns,
      //   data: cardWithdrawalData,
      //   selectorId: '',
      // },
      {
        title: FeesTableProps.landingFeesCryptoWithdrawalFees,
        columns: cryptoWithdrawlFeeColumns,
        data: FeesTableProps.cryptoWithdrawalData,
        selectorId: 'withdraw-crypto-fee-second',
        searchId: FeesTablesSelectorId.TABLE,
        disclaimerText:
          FeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
      },
      // {
      //   title: landingFeesInternalCryptoTransferFee,
      //   columns: cryptoColumns,
      //   data: internalTransferData,
      //   selectorId: 'convert-fee-second',
      // },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    FeesTableProps.cryptoWithdrawalData,
    FeesTableProps.shouldRender,
    FeesTableProps.landingFeesFundingOptions,
    FeesTableProps.landingFeesWithdrawalMethods,
  ]);
  return { tables };
};
