import Svg, { SvgIconDefaultProps } from '../BaseIcon';

const SolanaIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <Svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_35_5674)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_35_5674)">
        <path
          d="M12.6912 24.5933C12.8283 24.4562 13.0169 24.3762 13.2168 24.3762H31.3527C31.6841 24.3762 31.8498 24.7762 31.6155 25.0104L28.0329 28.593C27.8958 28.7302 27.7072 28.8102 27.5073 28.8102H9.37141C9.04 28.8102 8.8743 28.4102 9.10857 28.1759L12.6912 24.5933Z"
          fill="url(#paint0_linear_35_5674)"
        />
        <path
          d="M12.691 11.2171C12.8339 11.08 13.0224 11 13.2167 11H31.3525C31.6839 11 31.8496 11.4 31.6154 11.6342L28.0328 15.2168C27.8956 15.354 27.7071 15.434 27.5071 15.434H9.37124C9.03984 15.434 8.87413 15.034 9.1084 14.7997L12.691 11.2171Z"
          fill="url(#paint1_linear_35_5674)"
        />
        <path
          d="M28.0329 17.8624C27.8958 17.7252 27.7072 17.6452 27.5073 17.6452H9.37141C9.04 17.6452 8.8743 18.0452 9.10857 18.2795L12.6912 21.8621C12.8283 21.9992 13.0169 22.0792 13.2168 22.0792H31.3527C31.6841 22.0792 31.8498 21.6792 31.6155 21.445L28.0329 17.8624Z"
          fill="url(#paint2_linear_35_5674)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_35_5674"
          x1="29.6202"
          y1="8.85985"
          x2="17.0687"
          y2="32.9009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_35_5674"
          x1="24.1319"
          y1="5.99456"
          x2="11.5804"
          y2="30.0356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_35_5674"
          x1="26.8586"
          y1="7.41808"
          x2="14.3072"
          y2="31.4592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_35_5674">
          <rect width="40" height="40" fill="white" />
        </clipPath>
        <clipPath id="clip1_35_5674">
          <rect
            width="22.7241"
            height="17.8102"
            fill="white"
            transform="translate(9 11)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default SolanaIcon;
