import { JurisdictionsEnum } from '@libs/backend-common';
import { getJurisdictionBaseUrl } from '@libs/helpers';
import { Environment } from '@libs/types';

const { jurisdictionId } = getJurisdictionBaseUrl(Environment.prod);

export const INITIALIZE_TAG_ARGS = [
  JurisdictionsEnum.EU,
  JurisdictionsEnum.GE,
].includes(jurisdictionId);
