import { screenSizes } from '@libs/theme';
import { useMemo } from 'react';
import { FeesTablesSelectorId, InfoPaths } from '../../constants';
import TableCell from '../../table/TableCell';
import Typography from '../../typography/Typography';
import {
  minFoundingOptWithBankWireGel,
  minFoundingOptWithBankWireUsd,
  minFoundingOptWithBankwireWithdrawEur,
  minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelWithFee,
  minFoundingOptWithBankwireWithdrawUsd,
  minFoundingOptwithCard,
  minFoundingOptWithCardWithdraw,
  minFundingOptionCardOtherBank,
  minFundingOptionsWithBankWireOtherBankGel,
  minFundingOptionsWithBankWireOtherBankGelLarge,
} from '../constants';
import {
  CustomMinMaxText,
  IconWrapper,
  TextWrapperDesktop,
  TextWrapperMobile,
} from '../styles';
import { FeesTableProps } from '../types';

export const useTablesForMobileScreen = (FeesTableProps: FeesTableProps) => {
  const titleType =
    FeesTableProps.width < screenSizes.tablet ? 'primaryMedium' : 'primaryBold';

  const gelOrEurCurrency = ' ' + FeesTableProps.currencyGelOrEur;
  const usdCurrency = ' ' + FeesTableProps.currencyUsd;

  const tablesForMobileScreen = useMemo(() => {
    const depositCardOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: minFoundingOptwithCard + '%',
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const bankWireFeeDescForGe =
      minFoundingOptWithBankWireGel +
      FeesTableProps.currencyGelOrEur +
      ' /' +
      minFoundingOptWithBankWireUsd +
      FeesTableProps.currencyUsd;

    const bankWireFeeDescForEu =
      minFoundingOptWithBankWireGel + FeesTableProps.currencyGelOrEur;

    const depositBogBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelUsd,
      },
      {
        title: FeesTableProps.commonFee,
        description: FeesTableProps.isJurisdictionGe
          ? bankWireFeeDescForGe
          : bankWireFeeDescForEu,
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const depositOtherBankBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: FeesTableProps.isJurisdictionGe
          ? minFundingOptionCardOtherBank
          : minFoundingOptWithBankWireGel + FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawCardOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: minFoundingOptWithCardWithdraw + '%',
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawBogBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelUsd,
      },
      {
        title: FeesTableProps.commonFee,
        description: FeesTableProps.isJurisdictionGe
          ? minFoundingOptWithBankWireGel +
            gelOrEurCurrency +
            ' /' +
            minFoundingOptWithBankwireWithdrawUsd +
            usdCurrency
          : minFoundingOptWithBankwireWithdrawEur + '%',
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawOtherBankBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: FeesTableProps.isJurisdictionGe ? (
          <CustomMinMaxText
            fontFamily="primaryBold"
            color={FeesTableProps.defaultColor}
            type="caption"
          >
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {minFundingOptionsWithBankWireOtherBankGel +
                minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {minFundingOptionsWithBankWireOtherBankGelLarge +
                minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee +
                gelOrEurCurrency}
            </div>
          </CustomMinMaxText>
        ) : (
          minFoundingOptWithBankWireGel + gelOrEurCurrency
        ),
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const cryptoWithdrawlFeeColumns = [
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableAssets}
          </Typography>
        ),
        accessor: 'asset',
      },
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableBitnetFee}
          </Typography>
        ),
        accessor: 'fee',
      },
      {
        Header: (
          <Typography type="caption" fontFamily={titleType}>
            {FeesTableProps.tableBlockchainFee}
          </Typography>
        ),
        accessor: 'blockchainFee',
      },
      {
        Header: (
          <div>
            <TextWrapperDesktop>
              <Typography type="caption" fontFamily={titleType}>
                {FeesTableProps.tableAmount}
              </Typography>
            </TextWrapperDesktop>
            <TextWrapperMobile>
              <Typography type="caption" fontFamily={titleType}>
                {FeesTableProps.tableAmountShort}
              </Typography>
            </TextWrapperMobile>
          </div>
        ),
        accessor: 'amount',
      },
    ];

    return [
      {
        id: InfoPaths.DEPOSIT,
        title: FeesTableProps.isJurisdictionGe
          ? FeesTableProps.walletActionDeposit
          : FeesTableProps.landingFeesFundingOptions,
        content: [
          ...(FeesTableProps.isJurisdictionGe
            ? [
                {
                  title: FeesTableProps.walletActionDep,
                  options: depositCardOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  insideTitle: (
                    <TableCell
                      title={FeesTableProps.commonCard}
                      img={
                        <IconWrapper>
                          <FeesTableProps.CardIcon
                            color={FeesTableProps.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
          {
            options: depositBogBankWireOptions,
            selectorId: FeesTablesSelectorId.OPTION_LIST,
            title: FeesTableProps.shouldRender
              ? FeesTableProps.landingFeesFundingOptions
              : '',
            insideTitle: (
              <TableCell
                title={FeesTableProps.commonBankwireBogOrSepa}
                img={
                  <IconWrapper>
                    <FeesTableProps.BankIcon
                      color={FeesTableProps.brandColor}
                    />
                  </IconWrapper>
                }
                titleType={titleType}
                titleSize="caption"
              />
            ),
          },

          ...(FeesTableProps.isJurisdictionGe
            ? [
                {
                  options: depositOtherBankBankWireOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  disclaimerText: FeesTableProps.landingFeesFundingDisclaimer,
                  insideTitle: (
                    <TableCell
                      title={FeesTableProps.commonBankwireOther}
                      img={
                        <IconWrapper>
                          <FeesTableProps.BankIcon
                            color={FeesTableProps.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        id: InfoPaths.WITHDRAW_METHODS,
        title: FeesTableProps.commonWithdrawMethods,
        content: [
          ...(FeesTableProps.isJurisdictionGe
            ? [
                {
                  title: FeesTableProps.withdrawMeth,
                  options: withdrawCardOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  insideTitle: (
                    <TableCell
                      title={FeesTableProps.commonCard}
                      img={
                        <IconWrapper>
                          <FeesTableProps.CardIcon
                            color={FeesTableProps.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
          {
            options: withdrawBogBankWireOptions,
            selectorId: FeesTablesSelectorId.OPTION_LIST,
            title: FeesTableProps.shouldRender
              ? FeesTableProps.landingFeesWithdrawalMethods
              : '',
            insideTitle: (
              <TableCell
                title={FeesTableProps.commonBankwireBogOrSepa}
                img={
                  <IconWrapper>
                    <FeesTableProps.BankIcon
                      color={FeesTableProps.brandColor}
                    />
                  </IconWrapper>
                }
                titleType={titleType}
                titleSize="caption"
              />
            ),
          },
          ...(FeesTableProps.isJurisdictionGe
            ? [
                {
                  options: withdrawOtherBankBankWireOptions,
                  selectorId: FeesTablesSelectorId.OPTION_LIST,
                  disclaimerText:
                    FeesTableProps.landingFeesWithdrawalMethodsDisclaimer,
                  insideTitle: (
                    <TableCell
                      title={FeesTableProps.commonBankwireOther}
                      img={
                        <IconWrapper>
                          <FeesTableProps.BankIcon
                            color={FeesTableProps.brandColor}
                          />
                        </IconWrapper>
                      }
                      titleType={titleType}
                      titleSize="caption"
                    />
                  ),
                },
              ]
            : []),
        ],
      },
      {
        id: InfoPaths.WITHDRAW_CRYPTO_FEE,
        title: FeesTableProps.withdrawCryptoFee,
        content: [
          {
            title: FeesTableProps.landingFeesCryptoWithdrawalFees,
            columns: cryptoWithdrawlFeeColumns,
            data: FeesTableProps.cryptoWithdrawalData,
            selectorId: FeesTablesSelectorId.TABLE,
            disclaimerText:
              FeesTableProps.landingFeesCryptoWithdrawalMethodsDisclaimer,
          },
        ],
      },
      {
        id: InfoPaths.CONVERT_FEE,
        title: FeesTableProps.convertFee,
        content: [
          {
            selectorId: FeesTablesSelectorId.EXCHANGE_FEES_TABLE,
          },
        ],
      },
    ];
  }, [FeesTableProps, gelOrEurCurrency, usdCurrency, titleType]);

  return {
    tablesForMobileScreen,
  };
};
