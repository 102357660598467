import { useTheme } from 'styled-components';

import { AppFontKeys } from '@libs/theme';

import Typography, { TextType } from '../typography/Typography';

import {
  CellContainer,
  CellDescription,
  CellTitle,
  TextWrapper,
  Img,
  ImgWrapper,
  CustomTypography,
} from './styles';

type TableCellProps = {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  img?: string | JSX.Element;
  imgSize?: number;
  titleType?: AppFontKeys;
  titleSize?: TextType;
  descType?: TextType;
  secondaryDescType?: TextType;
  descFont?: AppFontKeys;
  color?: string;
  justifyToLeft?: boolean;
  className?: string;
  isAsset?: boolean;
  titleColor?: string;
  secondaryDesc?: string | JSX.Element;
  secondaryDescColor?: string;
  onDescriptionClick?: (e: React.MouseEvent) => void;
  testId?: string;
};

const TableCell: React.FC<TableCellProps> = ({
  title,
  description,
  titleType = 'primaryBold',
  titleSize = 'bodyButton',
  descType = 'caption',
  descFont = 'primaryMedium',
  imgSize,
  img,
  color,
  titleColor,
  justifyToLeft = true,
  className,
  isAsset = false,
  secondaryDesc,
  secondaryDescColor,
  onDescriptionClick,
  secondaryDescType,
  testId,
}) => {
  const { colors } = useTheme();

  const hasIcon = typeof img !== 'undefined';
  const icon =
    typeof img === 'string' ? (
      <Img imgSize={imgSize} src={img} alt="tableIcon icon" />
    ) : (
      img
    );

  const descColor = color ? color : colors.text.secondary;

  const additionalDescColor = secondaryDescColor
    ? secondaryDescColor
    : colors.text.default;

  return (
    <CellContainer data-cy="tableCell" className={className}>
      {hasIcon && <ImgWrapper data-cy="tableIcon">{icon}</ImgWrapper>}
      <TextWrapper justifyToLeft={justifyToLeft} className="text-wrapper">
        {title && (
          <CellTitle hasDesc={!!description}>
            <CustomTypography
              isAsset={isAsset}
              type={titleSize}
              fontFamily={titleType}
              testId={testId}
              className="TableCellTitle"
              color={titleColor || colors.text.default}
            >
              {title}
            </CustomTypography>
          </CellTitle>
        )}
        {description && (
          <CellDescription
            hasSecondaryDesc={!!secondaryDesc}
            onClick={onDescriptionClick}
            className="cellDescription"
          >
            <Typography
              className="TableCellDescription"
              testId="tableDesc"
              type={descType}
              fontFamily={descFont}
              color={descColor}
            >
              {description}
            </Typography>
            {secondaryDesc && (
              <Typography
                testId="tableDesc"
                type={secondaryDescType || descType}
                fontFamily={descFont}
                color={additionalDescColor}
              >
                {secondaryDesc}
              </Typography>
            )}
          </CellDescription>
        )}
      </TextWrapper>
    </CellContainer>
  );
};

export default TableCell;
