import { screenSizes } from '@libs/theme';
import { useMemo } from 'react';
import TableCell from '../../table/TableCell';
import {
  minFoundingOptWithBankWireGel,
  minFoundingOptWithBankWireUsd,
  minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee,
  minFoundingOptWithBankwireWithdrawTbcGelWithFee,
  minFoundingOptWithBankwireWithdrawUsd,
  minFoundingOptwithCard,
  minFoundingOptWithCardWithdraw,
  minFundingOptionCardOtherBank,
} from '../constants';
import { CustomMinMaxText, IconWrapper } from '../styles';
import { FeesTableProps } from '../types';

export const useLandingFeesMobileTables = (FeesTableProps: FeesTableProps) => {
  const titleType =
    FeesTableProps.width < screenSizes.tablet ? 'primaryMedium' : 'primaryBold';

  const gelOrEurCurrency = ' ' + FeesTableProps.currencyGelOrEur;

  const mobileTables = useMemo(() => {
    const depositCardOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: minFoundingOptwithCard + '%',
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const depositBogBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelUsd,
      },
      {
        title: FeesTableProps.commonFee,
        description:
          minFoundingOptWithBankWireGel +
          FeesTableProps.currencyGelOrEur +
          ' /' +
          minFoundingOptWithBankWireUsd +
          FeesTableProps.currencyUsd,
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const depositOtherBankBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: minFundingOptionCardOtherBank,
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawCardOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: minFoundingOptWithCardWithdraw + '%',
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawBogBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelUsd,
      },
      {
        title: FeesTableProps.commonFee,
        description:
          minFoundingOptWithBankWireGel +
          FeesTableProps.currencyGelOrEur +
          ' /' +
          minFoundingOptWithBankwireWithdrawUsd +
          FeesTableProps.currencyUsd,
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    const withdrawOtherBankBankWireOptions = [
      {
        title: FeesTableProps.commonCurrency,
        description: FeesTableProps.currencyGelOrEur,
      },
      {
        title: FeesTableProps.commonFee,
        description: (
          <CustomMinMaxText
            fontFamily="primaryBold"
            color={FeesTableProps.defaultColor}
            type="caption"
          >
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelMediumWithFee +
                gelOrEurCurrency}
            </div>
            <div>
              {'0 - ' +
                minFoundingOptWithBankwireWithdrawTbcGelMaxWithFee +
                gelOrEurCurrency}
            </div>
          </CustomMinMaxText>
        ),
      },
      {
        title: FeesTableProps.tableProccesing,
        description: FeesTableProps.commonInstant,
      },
      {
        title: FeesTableProps.commonLimit,
        description: FeesTableProps.commonUnlimited,
      },
    ];

    return [
      ...(FeesTableProps.isJurisdictionGe
        ? [
            {
              title: FeesTableProps.walletActionDep,
              options: depositCardOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonCard}
                  img={
                    <IconWrapper>
                      <FeesTableProps.CardIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: depositBogBankWireOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonBankwireBogOrSepa}
                  img={
                    <IconWrapper>
                      <FeesTableProps.BankIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: depositOtherBankBankWireOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonBankwireOther}
                  img={
                    <IconWrapper>
                      <FeesTableProps.BankIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              title: FeesTableProps.withdrawMeth,
              options: withdrawCardOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonCard}
                  img={
                    <IconWrapper>
                      <FeesTableProps.CardIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: withdrawBogBankWireOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonBankwireBogOrSepa}
                  img={
                    <IconWrapper>
                      <FeesTableProps.BankIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
            {
              options: withdrawOtherBankBankWireOptions,
              insideTitle: (
                <TableCell
                  title={FeesTableProps.commonBankwireOther}
                  img={
                    <IconWrapper>
                      <FeesTableProps.BankIcon
                        color={FeesTableProps.brandColor}
                      />
                    </IconWrapper>
                  }
                  titleType={titleType}
                  titleSize="caption"
                />
              ),
            },
          ]
        : []),
    ];
  }, [gelOrEurCurrency, FeesTableProps, titleType]);

  return { mobileTables };
};
