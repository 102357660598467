import Svg, { SvgIconDefaultProps } from '../BaseIcon';

const EtheriumIcon: React.FC<SvgIconDefaultProps> = ({ className }) => {
  return (
    <Svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_35_5663)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#627EEA"
        />
        <path
          d="M20.6226 5V16.0875L29.9938 20.275L20.6226 5Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path d="M20.6225 5L11.25 20.275L20.6225 16.0875V5Z" fill="white" />
        <path
          d="M20.6226 27.4602V34.994L30 22.0202L20.6226 27.4602Z"
          fill="white"
          fillOpacity="0.602"
        />
        <path
          d="M20.6225 34.994V27.459L11.25 22.0202L20.6225 34.994Z"
          fill="white"
        />
        <path
          d="M20.6226 25.7163L29.9938 20.2751L20.6226 16.0901V25.7163Z"
          fill="white"
          fillOpacity="0.2"
        />
        <path
          d="M11.25 20.2751L20.6225 25.7163V16.0901L11.25 20.2751Z"
          fill="white"
          fillOpacity="0.602"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_5663">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
export default EtheriumIcon;
